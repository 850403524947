import React from 'react';
import {useState} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth';

import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Grow from '@material-ui/core/Grow';

import Carousel from 'react-material-ui-carousel'
import { Paper, Button } from '@material-ui/core'


import FilipeMartins from "../images/team/fm.jpeg";
import TiagoMadeira from "../images/team/tm.jpeg";
import CarolinaSoares from "../images/team/cs.jpeg";
import MiguelParro from "../images/team/mp.jpeg";
import TiagoSilva from "../images/team/tsilva.jpeg";
import KevinRamos from "../images/team/kr.jpeg";
import AladjeSanha from "../images/team/as.jpeg";
import TiagoSousa from "../images/team/tsousa.jpeg";
import DanielGoncalves from "../images/team/dg.jpeg";
import MarynaPerova from "../images/team/map.jpeg";
import TiagoFernandes from "../images/team/tf.jpeg";
import RuiRego from "../images/team/rr.jpeg";
import GuilhermeMendonca from "../images/team/gm.jpeg";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        //height: 650,
        backgroundColor: theme.palette.secondary.dark,
        paddingBottom: theme.spacing(6)
    },
    grid: {
        //height: 500
        width: '80%',
        margin: 'auto'
    },
    imageBox: {
        //maxHeight: 500,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
    },
    media: {
        height: 0,
        paddingTop: '100%', // 16:9
        resizeMode:"cover", 
        opacity: '0.6',
        '&:hover': {
            opacity: '1',
          },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    title: {
        color: '#fff',
        fontSize: 24,
        fontWeight: 700,
        paddingTop: theme.spacing(6)
    },
    buttonUnderline: {
        backgroundColor: '#fff',
        height: 14,
        width: 50,
        margin: 'auto',
        marginTop: theme.spacing(3)
    },

    intro: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 500,
        marginTop: theme.spacing(8),
        textAlign: 'justify',
        textAlignLast: 'justify'
    },
    introBox: {
        width: 580,
        margin: 'auto'
    },
    gridItem: {
        paddingTop: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
        }
    },
    carousel: {
        //width: '90%',
        margin: 'auto'
    },
    name: {
        color: '#fff',
        fontSize: 20,
        marginTop: theme.spacing(2)
    },
    nameTitle: {
        color: '#fff',
        fontWeight: 200
    }
}))

function shuffleArray(array) {
    let i = array.length - 1;
    for (; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
    return array;
}

const items = [
    {
        name: "Carolina Soares",
        description: "designer gráfica",
        image: CarolinaSoares
    },
    {
        name: "Tiago Madeira",
        description: "web designer",
        image: TiagoMadeira
    },
    {
        name: "Filipe Martins",
        description: "marketing",
        image: FilipeMartins
    },
    {
        name: "Daniel Gonçalves",
        description: "designer gráfica",
        image: DanielGoncalves
    },
    {
        name: "Miguel Parro",
        description: "web designer",
        image: MiguelParro
    },
    {
        name: "Tiago Silva",
        description: "marketing",
        image: TiagoSilva
    },
    // {
    //     name: "Tiago Sousa",
    //     description: "designer gráfica",
    //     image: TiagoSousa
    // },
    {
        name: "Aladje Sanhá",
        description: "web designer",
        image: AladjeSanha
    },
    {
        name: "Kevin Ramos",
        description: "marketing",
        image: KevinRamos
    },
    {
        name: "Maryna Perova-Lashchenko",
        descritpion: "FrontEnd Developer",
        image: MarynaPerova
    },
    {
        name: "Rui Rego",
        descritpion: "Product Owner",
        image: RuiRego
    },
    {
        name: "Tiago Fernandes",
        descritpion: "BackEnd Developer",
        image: TiagoFernandes
    },
    {
        name: "Guilherme Mendonça",
        descritpion: "Data and Analytics",
        image: GuilhermeMendonca
    }
]

function Item(props) {
    const classes = useStyles();
    var index = props.index;
    var item = props.item;

    return (
        <div key={index}>
            <Grid className={classes.grid} container >
                <Grid item className={classes.gridItem} xs={12} sm={4} md={4}>
                    <Card className={classes.imageBox} >
                        <CardMedia
                            className={classes.media}
                            image={item[0].image}
                        />
                    </Card>

                    <Typography className={classes.name}>{item[0].name}</Typography>
                    {//<Typography className={classes.nameTitle}>{item[0].description}}</Typography>
}
                </Grid>
                {chunkSize === 3 &&
                <Grid item className={classes.gridItem} xs={12} sm={4} md={4}>
                    {
                        item[1] != null &&
                        <Card className={classes.imageBox} >
                            <CardMedia
                                className={classes.media}
                                image={item[1].image}
                            />
                        </Card>}
                    {
                        item[1] != null &&
                        <Typography className={classes.name}>{item[1].name}</Typography>}
                    {
                        /*item[1] != null &&
                        <Typography className={classes.nameTitle}>{item[1].description}</Typography>*/
                        }
                </Grid>}

                {chunkSize === 3 &&
                <Grid item className={classes.gridItem} xs={12} sm={4} md={4}>
                    {item[2] != null &&
                        <Card className={classes.imageBox} >
                            <CardMedia
                                className={classes.media}
                                image={item[2].image}
                            />
                        </Card>}
                    {item[2] != null &&
                        <Typography className={classes.name}>{item[2].name}</Typography>}
                    {/*item[2] != null &&
                        <Typography className={classes.nameTitle}>{item[2].description}</Typography>*/
                        
                        }
                </Grid>}
            </Grid>
        </div>
    )
}

var chunkSize = 3;

function TeamSection(props) {

    const classes = useStyles();
    const [randomArray, setRandomArray] = useState([]);

    if (isWidthDown('xs', props.width)) {
        chunkSize = 1;
    } else {
        chunkSize = 3;
    }


    //debugger

    if(randomArray.length == 0) {
        setRandomArray(shuffleArray(items));
    }

    const groups = randomArray.map((e, i) => {
        return i % chunkSize === 0 ? randomArray.slice(i, i + chunkSize) : null;
    }).filter(e => { return e; });

    return (
        <div className={classes.root}>


            <Typography className={classes.title}>TEAM</Typography>
            <Grow timeout={1000} in={props.render}><div className={classes.buttonUnderline} /></Grow>

            <Carousel className={classes.carousel} animation='slide' >
                {
                    groups.map((item, i) => <Item index={i} item={item} />)
                }
            </Carousel>
        </div>
    )
}


export default withWidth()(TeamSection);